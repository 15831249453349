<template>
  <div class="campaign">
    <div class="d-flex justify-space-between align-center mb-4">
      <h4>Campaigns</h4>
      <v-btn
        depressed
        dark
        large
        color="primary"
        style="width: 252px;"
        to="/campaign/create"
      >Create Campaign</v-btn>
    </div>

    <v-progress-linear
      indeterminate
      color="primary"
      v-if="loading"
    ></v-progress-linear>

    <v-simple-table
      class="mb-4"
      v-if="campaignData.length > 0"
    >
      <template v-slot:default>
        <thead>
          <tr>
            <th
              class="text-center"
              width="50"
            >Sr.</th>
            <th
              class="text-center"
              width="200"
            >Campaign Name</th>
            <th
              class="text-center"
              width="100"
            >Campaign Type</th>
            <th
              class="text-center"
              width="100"
            >Content Type</th>
            <th
              class="text-center"
              width="150"
            >Category</th>
            <th
              class="text-center"
              width="150"
            >Language</th>
            <th class="text-center">Gender</th>
            <th class="text-center">Budget</th>
            <th class="text-center">Status</th>
            <th class="text-left">Add Time</th>
            <th class="text-center"></th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(item, index) in campaignData"
            :key="item.id"
             @click="rowClick(item)"
             style="cursor:pointer"
          >  <!-- click.prevent is used to prevent the default behavior of an element -->
           <!-- click.stop is used to stop the click event from propagating further to the parent elements. -->
            <td class="text-center">{{ index + 1 }}</td>
            <td>
              <div class="d-flex align-center">
                <div class="mr-2">
                  <v-icon
                    v-if=" item.promo_platform === 1"
                    color="#ff0101"
                    large
                  >mdi-youtube</v-icon>
                  <v-img
                    v-else
                    src="@/assets/images/icon-instagram.svg"
                    width="28"
                  ></v-img>
                </div>
                <div>{{ item.campaign_name }}</div>
              </div>
            </td>
            <td class="text-center">{{ item.campaign_type | campaignType }}</td>
            <td
              class="text-center"
              v-if="item.promo_platform === 1"
            >{{ item.content_type | youtube  }}</td>
            <td
              class="text-center"
              v-else
            >{{ item.content_type | instagram  }}</td>
            <td class="text-center">
              <span
                v-for="category in item.category"
                :key="category"
              >{{ category }}, </span>
            </td>
            <td class="text-center">
              <span
                v-for="language in item.language"
                :key="language"
              >{{ language }}, </span>
            </td>
            <td class="text-center">
              <span
                v-for="gender in item.gender"
                :key="gender"
              >{{ gender }}, </span>
            </td>
            <td class="text-center">{{ item.budget | formatNumber }}</td>
            <td class="text-center">
              <div @click.stop="logs(item.campaign_id, item.promo_platform, item.client_id)">
                <v-chip
                  v-if="item.campaign_status === 0"
                  color="warning"
                  small
                  link
                  outlined
                >
                  <v-icon
                    small
                    left
                  >mdi-alert-circle-outline</v-icon>Draft
                </v-chip>
                <v-chip
                  v-if="item.campaign_status === 1"
                  color="success"
                  small
                  link
                  outlined
                >
                  <v-icon
                    small
                    left
                  >mdi-progress-clock</v-icon>Progress
                </v-chip>
                <v-chip
                  v-if="item.campaign_status === 2"
                  color="error"
                  small
                  link
                  outlined
                >
                  <v-icon
                    small
                    left
                  >mdi-close-circle-outline</v-icon>Cancelled
                </v-chip>
                <v-chip
                  v-if="item.campaign_status === 4"
                  color="primary"
                  small
                  link
                  outlined
                >
                  <v-icon
                    small
                    left
                  >mdi-check-circle-outline</v-icon>Completed
                </v-chip>
              </div>
            </td>
            <td class="text-center">{{ item.add_time | dateFormat }}</td>
            <td class="text-center">
              <v-btn
                class="mb-1"
                color="primary"
                depressed
                outlined
                x-small
                fab
                :to="`/campaign/details/${item.campaign_id}/0/${item.promo_platform}`"
              >
                <v-icon small>mdi-card-text-outline</v-icon>
              </v-btn>
              <v-btn
                color="primary"
                depressed
                outlined
                x-small
                fab
                @click.stop="editCampaign(item)"
              >
                <!-- :to="`/campaign/edit/${item.campaign_id}/${item.promo_platform}`" -->
                <v-icon small>mdi-pencil</v-icon>
              </v-btn>
              <!-- <v-btn color="primary" depressed outlined x-small fab :to="`/campaign/post/${item.campaign_id+'/'+item.promo_platform}`">
                <v-icon small>mdi-chart-bar</v-icon>
              </v-btn> -->
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>

    <v-pagination
      v-model="params.page"
      :length="this.totalPages"
      @input="getList()"
      :total-visible="10"
      v-if="!loading && this.totalPages > 1"
    ></v-pagination>

    <div
      class="text-center ma-8"
      v-if="!loading && campaignData.length <= 0"
    >
      <img
        src="@/assets/images/no-campaign.png"
        width="150"
      />
      <h3>No Campaigns Found</h3>
      <v-btn
        depressed
        dark
        large
        color="primary"
        to="/campaign/create"
        style="width: 350px;"
      >Create your First Campaign</v-btn>
    </div>
  </div>
</template>

<script>
import { getList } from '@/api/campaign'

export default {
  data () {
    return {
      loading: false,
      campaignData: [],
      totalPages: 0,
      params: {
        page: 1,
        size: 10
      }
    }
  },
  mounted () {
    this.getList()
  },
  methods: {
    async getList () {
      this.campaignData = []
      this.loading = true
      const data = await getList(this.params)
      if (data.success) {
        this.campaignData = data.campaigns.map(item => {
          item.category = item.category ? item.category.split(',') : []
          item.language = item.language ? item.language.split(',') : []
          item.gender = item.gender ? item.gender.split(',') : []
          return item
        })
        this.totalPages = Math.ceil(data.total_count / 10)
      } else {
        this.$snackbar({
          message: data.message,
          color: 'error',
          timeout: 5000
        })
        this.campaignData = []
      }
      this.loading = false
    },
    logs (campaignId, Platform, clientId) {
      this.$router.push(`/campaign/details/${campaignId}/3/${Platform}`)
    },
    rowClick (item) {
      this.$router.push(`/campaign/details/${item.campaign_id}/0/${item.promo_platform}`)
    },
    editCampaign (item) {
      this.$router.push(`/campaign/edit/${item.campaign_id}/${item.promo_platform}`)
    }
  }
}
</script>

<style scoped>
  h4 {
    text-transform: uppercase;
    margin: 0;
  }
  h3 {
    font-weight: 500;
    font-size: 23px;
    line-height: 34px;
    color: #6b6b6b;
    margin: 20px 0;
  }
  .v-data-table >>> .v-data-table__wrapper > table {
    border-spacing: 0 12px !important;
  }
  .theme--light.v-data-table .v-data-table__wrapper table th {
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    color: #333333;
    border: none !important;
    padding: 16px 10px;
  }
  .v-data-table > .v-data-table__wrapper > table > tbody > tr > td {
    font-weight: normal;
    font-size: 14px;
    color: #333333;
    border: none !important;
    padding: 16px 10px;
  }
  .v-data-table > .v-data-table__wrapper > table > tbody > tr {
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.09);
    border-radius: 13px;
  }
</style>
