import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import '@/components/snackbar'
import vuetify from '@/plugins/vuetify'
import '@/utils/filters'
import VueApexCharts from 'vue-apexcharts'
import VueGtag from 'vue-gtag'

// GA Analytics
// Vue.use(VueGtag, { config: { id: 'UA-195037241-1' } })
Vue.use(VueGtag, { config: { id: 'GTM-NN85PZV' } })

Vue.use(VueApexCharts)
Vue.component('apexchart', VueApexCharts)
Vue.config.productionTip = false

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
