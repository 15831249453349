import request from './index'

export const getList = (data) => {
  return request({
    method: 'GET',
    url: '/campaign/list',
    params: data
  })
}

export const assignDelete = (data) => {
  return request({
    method: 'DELETE',
    url: '/campaign/assignInfluencer/delete',
    data: data
  })
}

export const getInfo = (data) => {
  return request({
    method: 'GET',
    url: '/campaign/campaigndetails',
    params: data
  })
}

export const getInfluencerInfo = (campaignId, platform) => {
  return request({
    method: 'GET',
    url: `/getInfluencerInfoDetails/${campaignId}/${platform}`
  })
}

export const getPost = (campaignId, type) => {
  return request({
    method: 'GET',
    url: `/campaign/${campaignId}/${type}`
  })
}

export const createCampaign = (data) => {
  return request({
    method: 'POST',
    url: '/campaign/create',
    data: data
  })
}

export const updateCampaign = (data) => {
  return request({
    method: 'POST',
    url: '/campaign/update',
    data: data
  })
}

export const deleteCampaign = (id) => {
  return request({
    method: 'DELETE',
    url: `/campaign/${id}`
  })
}

export const getStats = (campaignId, platform) => {
  return request({
    method: 'GET',
    url: `/campaign/data/stats/${campaignId}/${platform}`
  })
}

export const getLog = (campaignId) => {
  return request({
    method: 'GET',
    url: `/campaign/campaignLogs/logs/${campaignId}`
  })
}
