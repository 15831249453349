<template>
  <v-app>

    <v-app-bar color="white" class="elevation-0" height="74" app>

      <v-toolbar-title>
        <a href="https://www.soocel.com" class="d-flex justify-center align-center">
          <img src="@/assets/images/soocel-logo.png" alt="Soocel" height="45" />
        </a>
      </v-toolbar-title>

      <v-spacer></v-spacer>

      <ul class="d-flex justify-end">
        <li>
          <a href="https://www.soocel.com">Home</a>
        </li>
        <li>
          <router-link to="/influencer/list">Influencer List</router-link>
        </li>
        <li>
          <router-link to="/campaign/create">Post a Campaign</router-link>
        </li>
      </ul>

      <v-menu left bottom offset-y v-if="user && user.id">
        <template v-slot:activator="{ on, attrs }">
          <v-btn plain v-bind="attrs" v-on="on" class="userBtn" :ripple="false">
            <img :src="user.cover" width="32" v-if="user.cover && user.cover != null" />
            <img :src="`https://ui-avatars.com/api/?background=4C62E3&color=fff&name=${user.name}`" width="32" v-else />
            <span class="text-left">
              {{user.name}}
            </span>
          </v-btn>
        </template>

        <v-list>
          <v-list-item to="/profile/info">
            <v-list-item-title>Profile</v-list-item-title>
          </v-list-item>
          <v-list-item @click="logout">
            <v-list-item-title>Logout</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>

      <v-btn depressed color="white" class="loginBtn" :to="`/user/login?redirect=${this.$route.path}`" v-else>Login</v-btn>

    </v-app-bar>

    <v-main>
      <v-container class="px-6 router-view" fluid>
        <div class="d-flex align-start">
          <v-card class="pa-2 mr-6 siderMenu" v-if="user && user.id">
            <v-list>
              <v-list-item to="/dashboard">
                <v-list-item-content>
                  <v-list-item-title>Dashboard</v-list-item-title>
                </v-list-item-content>
                <v-list-item-icon>
                  <img src="@/assets/images/menu_arrow.svg" width="8" />
                </v-list-item-icon>
              </v-list-item>
              <v-list-item to="/campaign/list" :class="this.$route.matched[0].name === 'campaign' ? 'v-list-item--active' : ''">
                <v-list-item-content>
                  <v-list-item-title>Campaigns</v-list-item-title>
                </v-list-item-content>
                <v-list-item-icon>
                  <img src="@/assets/images/menu_arrow.svg" width="8" />
                </v-list-item-icon>
              </v-list-item>
              <v-list-item to="/influencer/list" :class="this.$route.matched[0].name === 'influencer' ? 'v-list-item--active' : ''">
                <v-list-item-content>
                  <v-list-item-title>Influencer Selection</v-list-item-title>
                </v-list-item-content>
                <v-list-item-icon>
                  <img src="@/assets/images/menu_arrow.svg" width="8" />
                </v-list-item-icon>
              </v-list-item>
              <v-list-item to="/influencer/favourite">
                <v-list-item-content>
                  <v-list-item-title>Fav. Inffluencers</v-list-item-title>
                </v-list-item-content>
                <v-list-item-icon>
                  <img src="@/assets/images/menu_arrow.svg" width="8" />
                </v-list-item-icon>
              </v-list-item>
              <v-list-item to="/profile/info" class="no-border">
                <v-list-item-content>
                  <v-list-item-title>Profile</v-list-item-title>
                </v-list-item-content>
                <v-list-item-icon>
                  <img src="@/assets/images/menu_arrow.svg" width="8" />
                </v-list-item-icon>
              </v-list-item>
            </v-list>
          </v-card>
          <v-row>
            <v-col cols="12">
              <transition name="fade-transform" mode="out-in">
                <router-view />
              </transition>
            </v-col>
          </v-row>
        </div>
      </v-container>

      <footer>
        <v-container>
          <!-- <v-row>
            <v-col cols="11" offset-xl="2" offset-md="1">
              <div class="footerLogo d-flex align-center justify-center">
                <img src="@/assets/images/footer-logo.svg" width="46" />
              </div>
            </v-col>
            <v-col cols="4" offset-xl="2" offset-md="1">
              <h4>Soocel</h4>
              <p>
                Yashshreee Park, S.NO. 34/1 <br/>
                Vidya Nagar Plot No.27, <br/>
                Dhanori, Pune-411032 Maharashtra (India)
              </p>
            </v-col>
            <v-col cols="3">
              <h4>Legal</h4>
              <p><router-link to="/legal/privacy-policy">Privacy Policy</router-link></p>
              <p><router-link to="/legal/terms">Terms &amp; Conditions</router-link></p>
            </v-col>
            <v-col cols="3">
              <h4>Contact Us</h4>
              <p><a href="mailto:support@soocel.com">Email - support@soocel.com</a></p>
            </v-col>
          </v-row> -->
          <p class="text-center copyright"> &copy;2022 Soocel Digital Solutions Private Limited</p>
        </v-container>
      </footer>

    </v-main>
  </v-app>
</template>

<script>
export default {
  data () {
    return {
      user: JSON.parse(window.localStorage.getItem('user'))
    }
  },
  methods: {
    logout () {
      document.cookie = 'client_token=; path=/; secure; domain=.soocel.com; expires=Thu, 01 Jan 1970 00:00:01 GMT;'
      window.localStorage.clear()
      this.$router.push(`/user/login?redirect=${this.$route.path}`)
    }
  }
}
</script>

<style scoped>
  .router-view {
    min-height: 90vh;
  }
  .page-view {
    width: 100%;
    overflow: hidden;
  }
  .siderMenu  {
    min-width: 280px;
    max-width: 280px;
  }
  .siderMenu .v-list .v-list-item {
    /* transition: ease-in-out .3s; */
    text-transform: uppercase;
    border-bottom: 1px solid #D9D9D9;
    color: #6B6B6B;
  }
  .siderMenu .v-list .v-list-item.no-border {
    border-bottom: 0;
  }
  .v-list >>> .v-list-item--active,
  .v-list >>> .v-list-item:hover,
  .v-list-item--link:before {
    background: #FFFFFF;
    color: #2F2965 !important;
    font-weight: 600;
  }
  .v-list-item--active >>> .v-list-item__icon img,
  .v-list-item:hover >>> .v-list-item__icon img {
    filter: sepia(79%) saturate(59%) hue-rotate(246deg) brightness(40%) contrast(100%);
  }
  .v-list-item__action, .v-list-item__avatar, .v-list-item__icon {
    min-width: auto;
  }

  .v-btn.loginBtn {
    background: url('../assets/images/login-btn-bg.svg') no-repeat center center;
    width: 176px;
    height: 46px;
    font-weight: 600;
    font-size: 18px;
    line-height: 27px;
  }
  .v-btn.userBtn {
    font-size: 14px;
    color: #34335B;
    text-transform: capitalize;
    letter-spacing: normal;
    margin: -5px 0 0;
  }
  .v-btn.userBtn img {
    border-radius: 50%;
    margin: 0 5px 0 0;
  }
  .v-btn.userBtn small {
    color: grey;
  }
  .v-btn.userBtn >>> .v-btn__content .v-icon--right {
    margin-left: 0;
  }
  .v-btn--plain >>> .v-btn__content {
    opacity: 1 !important;
  }

  .v-app-bar >>> ul {
    list-style-type: none;
    margin: 0 20px 0 0;
  }
  .v-app-bar >>> ul li a {
    display: inline-block;
    font-weight: 500;
    font-size: 16px;
    padding: 0 20px;
    color: #1F2964;
    transition: ease-in-out .25s;
  }
  .v-app-bar >>> ul li a:hover,
  .v-app-bar >>> ul li a.router-link-active {
    color: #F54153;
  }

  footer {
    margin: 20px 0 0;
    background: #454545;
    padding: 20px 0 0;
    color: #FFFFFF;
  }
  .footerLogo {
    width: 57px;
    height: 57px;
    background: #FFFFFF;
    box-shadow: 0px 1px 17px rgba(0, 0, 0, 0.21);
    border-radius: 6px;
  }
  footer h4 {
    margin: 0 0 10px;
  }
  footer p a {
    color: #FFFFFF;
    display: inline-block;
  }
  footer p.copyright {
    margin: 0 0 10px;
  }

  /* route fade-transform */
  .fade-transform-leave-active,
  .fade-transform-enter-active {
    transition: all .3s;
  }
  .fade-transform-enter {
    opacity: 0;
    transform: translateX(-30px);
  }
  .fade-transform-leave-to {
    opacity: 0;
    transform: translateX(30px);
  }
</style>
