import request from './index'

export const getSimulation = (data) => {
  return request({
    method: 'GET',
    url: '/site/campaign-simulation',
    params: data
  })
}

export const dashboard = (type) => {
  return request({
    method: 'GET',
    url: `/dashboard/${type}`
  })
}
