<template>
  <div class="campaign-details">

    <v-tabs
      v-model="tab"
      class="mb-6"
      background-color="grey lighten-4"
    >
      <v-tab @change="getCampaignDetails()">
        <v-icon class="mr-2">mdi-note-text</v-icon>
        Details
      </v-tab>
      <v-tab>
        <v-icon class="mr-2">mdi-account-group</v-icon>
        Influencers
      </v-tab>
      <v-tab>
        <v-icon class="mr-2">mdi-chart-arc</v-icon>
        Analytics
      </v-tab>
      <v-tab @change="getLogs()">
        <v-icon class="mr-2">mdi-book-open-page-variant-outline</v-icon>
        Logs
      </v-tab>
    </v-tabs>

    <v-tabs-items v-model="tab">
      <v-tab-item>
        <v-progress-linear
          indeterminate
          color="primary"
          v-if="loading"
        ></v-progress-linear>

        <div v-else>
          <div class="d-flex">
            <v-img
              :src="campaign.brand_image"
              lazy-src="@/assets/images/placeholder.png"
              :aspect-ratio="16/16"
              width="180"
            />
            <v-card
              class="ml-4"
              style="width: 100%;"
            >
              <v-card-text>
                <h5 class="mb-6">{{ campaign.campaign_name }}</h5>

                <div class="d-flex align-center justify-space-between text-center mb-4">
                  <p>
                    <span class="label">Platform</span> <br>
                    <strong>{{ campaign.promo_platform | platform }}</strong>
                  </p>
                  <p>
                    <span class="label">Campaign Type</span> <br>
                    <strong>{{ campaign.campaign_type | campaignType }}</strong>
                  </p>
                  <p>
                    <span class="label">Content Type</span> <br>
                    <strong v-if="campaign.promo_platform === 1">{{ campaign.content_type | youtube }}</strong>
                    <strong v-else>{{ campaign.content_type | instagram }}</strong>
                  </p>
                  <p>
                    <span class="label">Budget</span> <br>
                    <strong v-if="campaign.budget">{{campaign.budget | formatNumber}}</strong>
                    <strong v-else>-</strong>
                  </p>
                  <p>
                    <span class="label">Application Timeline</span> <br>
                    <strong>{{ campaign.start_date | dateFormat}} ~ {{ campaign.end_date | dateFormat}}</strong>
                  </p>
                </div>
              </v-card-text>
            </v-card>
          </div>

          <v-card class="elevation-0 grey-bg mt-6 mb-6">
            <v-card-text>
              <h5 class="mb-6">Eligibility Criteria</h5>

              <div class="d-flex align-center justify-space-between text-center">
                <p>
                  <span class="label">Category</span> <br>
                  <strong>
                    <span
                      v-for="category in campaign.category"
                      :key="category"
                    >{{ category }}, </span>
                  </strong>
                </p>
                <p>
                  <span class="label">Language</span> <br>
                  <strong>
                    <span
                      v-for="language in campaign.language"
                      :key="language"
                    >{{ language }}, </span>
                  </strong>
                </p>
                <p>
                  <span class="label">Gender</span> <br>
                  <strong>
                    <span
                      v-for="gender in campaign.gender"
                      :key="gender"
                    >{{ gender }}, </span>
                  </strong>
                </p>
                <p>
                  <span
                    class="label"
                    v-if="campaign.promo_platform===1"
                  >Subscribers</span>
                  <span
                    class="label"
                    v-else
                  >Followers</span>
                  <br>
                  <strong>{{ campaign.min_follower | formatNumber }} ~ {{ campaign.max_follower | formatNumber }}</strong>
                </p>
              </div>

            </v-card-text>
          </v-card>

          <v-card class="elevation-0 grey-bg mb-6">
            <v-card-text>
              <h5 class="mb-6">Description of Campaign</h5>
              <p>
                <strong>
                  <span>{{ campaign.campaign_desc || '-' }}</span>
                </strong>
              </p>

            </v-card-text>
          </v-card>

          <v-card class="elevation-0 grey-bg mb-6">
            <v-card-text>
              <h5 class="mb-6">Deliverable</h5>
              <p>
                <strong>
                  <span>{{ campaign.deliverable || '-' }}</span>
                </strong>
              </p>
            </v-card-text>
          </v-card>
        </div>
        <div v-if="!loading && this.selectedInfluencers.length > 0 && campaign.campaign_name">
          <h5 class="ml-4">Approved Influencers</h5>
          <div v-if="campaign.promo_platform === 1">
            <v-data-table
              :headers="headersyoutube"
              :disable-sort="true"
              :items="indexedItems"
              :loading="loading2"
              loading-text="Loading... Please wait"
              mobile-breakpoint="0"
              hide-default-footer
            >
              <!-- :items-per-page="20" -->
              <!-- :footer-props="{'items-per-page-options': [20,40,60,80,100]}" -->
              <template v-slot:[`item.sr`]="{ item }">{{params.page === 1 ? item.sr : item.sr + ((params.page - 1 ) * params.size)}}</template>
              <template v-slot:[`item.avatar`]="{ item }">
                <a
                  :href="item.channel_link"
                  target="_blank"
                  class="d-flex align-center"
                >
                  <div>
                    <v-img
                      v-if="item.avatar"
                      :src="item.avatar"
                      class="img"
                    ></v-img>
                    <!-- <div
                    class="team-image"
                    v-else
                  >
                    {{item.channel_name?item.channel_name.charAt(0): 'NA'}}
                  </div> -->
                    <v-img
                      v-else
                      class="img"
                      :src="`https://ui-avatars.com/api/?name=${item.channel_name}&background=F54153&color=FFF`"
                    ></v-img>
                  </div>
                  <span class="category">
                    <strong>{{ item.channel_name }}</strong> <br>
                    <v-chip
                      v-if="item.category"
                      x-small
                      color="teal"
                      text-color="white"
                    >
                      {{ item.category }}
                    </v-chip>
                  </span>
                </a>
              </template>
              <template v-slot:[`item.yt_category`]="{ item }">
                {{item.yt_category ? catName(item.yt_category) : '-'}}
              </template>
              <template v-slot:[`item.language`]="{ item }">
                {{item.language ? item.language : '-'}}
              </template>
              <template v-slot:[`item.gender`]="{ item }">
                {{item.gender ? item.gender : '-'}}
              </template>
              <template v-slot:[`item.eng_rate`]="{ item }">
                {{ item.eng_rate ? item.eng_rate + '%' : '-'}}
              </template>
              <template v-slot:[`item.total_video`]="{ item }">
                {{item.total_video | formatNumber}}
              </template>
              <template v-slot:[`item.subscribers`]="{ item }">
                {{item.subscribers | formatNumber}}
              </template>
              <template v-slot:[`item.total_view`]="{ item }">
                {{item.total_view | formatNumber}}
              </template>
              <template v-slot:[`item.latest_avg_view`]="{ item }">
                {{item.latest_avg_view | formatNumber}}
              </template>
              <template v-slot:[`item.add_time`]="{ item }">
                {{item.add_time | dateFormat}}
              </template>
              <!-- <template v-slot:[`item.status`]="{ item }">
              <v-chip
                v-if="item.status === 0"
                color="warning"
                small
                outlined
              >
                <v-icon
                  small
                  left
                >mdi-alert-circle-outline</v-icon> Pending
              </v-chip>
              <v-chip
                v-if="item.status === 1"
                color="success"
                small
                outlined
              >
                <v-icon
                  small
                  left
                >mdi-check-circle-outline</v-icon> Approved
              </v-chip>
              <v-chip
                v-if="item.status === 2"
                color="error"
                small
                outlined
              >
                <v-icon
                  small
                  left
                >mdi-close-octagon-outline</v-icon> Rejected
              </v-chip>
            </template> -->
              <template v-slot:[`item.last_video_date`]="{ item }">
                {{item.last_video_date | dateFormat }}
              </template>
            </v-data-table>
          </div>
          <div v-else>
            <v-data-table
              :headers="headersinsta"
              :disable-sort="true"
              :items="indexedItems"
              :loading="loading2"
              mobile-breakpoint="0"
              hide-default-footer
              loading-text="Loading... Please wait"
            >
              <template v-slot:[`item.sr`]="{ item }">{{params.page === 1 ? item.sr : item.sr + ((params.page - 1 ) * params.size)}}</template>
              <template v-slot:[`item.avatar`]="{ item }">
                <a
                  :href="item.insta_link"
                  target="_blank"
                  class="d-flex align-center"
                >
                  <!-- <div>
                  <v-img
                    v-if="item.avatar"
                    :src="item.avatar"
                    class="avatar"
                    :aspect-ratio="16/16"
                  ></v-img>
                  <div
                    v-else
                    class="team-image"
                  >
                    {{item.insta_name?item.insta_name.charAt(0,2): 'NA'}}
                  </div>
                </div> -->
                  <div>
                    <v-badge
                      v-if="item.is_verify"
                      color="success"
                      icon="mdi-check"
                      avatar
                      bordered
                      overlap
                    >
                      <v-avatar size="45">
                        <v-img
                          class="img"
                          :src="`https://ui-avatars.com/api/?name=${item.insta_name}&background=F54153&color=FFF`"
                        ></v-img>
                      </v-avatar>
                    </v-badge>
                    <v-img
                      v-else
                      class="img"
                      :src="`https://ui-avatars.com/api/?name=${item.insta_name}&background=F54153&color=FFF`"
                    ></v-img>
                  </div>
                  <span class="category">
                    <strong>{{ item.insta_name }}</strong> <br>
                    <v-chip
                      v-if="item.category"
                      x-small
                      color="teal"
                      text-color="white"
                    >
                      {{ item.category }}
                    </v-chip>
                  </span>
                </a>
              </template>
              <template v-slot:[`item.insta_name`]="{ item }">
                <span>
                  {{item.insta_name ? item.insta_name : '-'}} </span>
                <v-chip
                  v-if="item.category"
                  x-small
                  color="teal"
                  text-color="white"
                >
                  {{ item.category }}
                </v-chip>
              </template>
              <template v-slot:[`item.category`]="{ item }">
                {{item.category ? item.category : '-'}}
              </template>
              <template v-slot:[`item.language`]="{ item }">
                {{item.language ? item.language : '-'}}
              </template>
              <template v-slot:[`item.gender`]="{ item }">
                {{item.gender ? item.gender : '-'}}
              </template>
              <template v-slot:[`item.total_media`]="{ item }">
                {{item.total_media | formatNumber}}
              </template>
              <template v-slot:[`item.followers`]="{ item }">
                {{item.followers | formatNumber}}
              </template>
              <template v-slot:[`item.add_time`]="{ item }">
                {{item.add_time | dateFormat}}
              </template>
              <template v-slot:[`item.status`]="{ item }">
                <v-chip
                  v-if="item.status === 0"
                  color="warning"
                  small
                  outlined
                >
                  <v-icon
                    small
                    left
                  >mdi-alert-circle-outline</v-icon> Pending
                </v-chip>
                <v-chip
                  v-if="item.status === 1"
                  color="success"
                  small
                  outlined
                >
                  <v-icon
                    small
                    left
                  >mdi-check-circle-outline</v-icon> Approved
                </v-chip>
                <v-chip
                  v-if="item.status === 2"
                  color="error"
                  small
                  outlined
                >
                  <v-icon
                    small
                    left
                  >mdi-close-octagon-outline</v-icon> Rejected
                </v-chip>
              </template>
              <!-- <template v-slot:[`item.is_business`]="{ item }">
              {{item.is_business !== null ? account(item.is_business) : '-'}}
            </template> -->
              <template v-slot:[`item.id`]="{ item }">
                <div
                  class="d-flex align-center justify-center"
                  v-if="item.status === 0"
                >
                  <!-- <v-icon
                  color="#F54153"
                  @click="assignedDelete(item.id)"
                >mdi-delete</v-icon> -->
                  <v-btn
                    icon
                    dark
                    color="secondary"
                    size="28"
                    @click="assignedDelete(item)"
                  >
                    <v-icon>mdi-delete</v-icon>
                  </v-btn>
                </div>
              </template>
            </v-data-table>
          </div>
          <v-pagination
            v-model="params.page"
            :length="this.totalPages"
            @input="getInfluencerData()"
            :total-visible="10"
            v-if="!loading2 && totalPages > 1"
          ></v-pagination>
        </div>
      </v-tab-item>
      <v-tab-item>
        <Influencer v-if="tab === 1" />
      </v-tab-item>
      <v-tab-item>
        <Stats v-if="tab === 2" />
      </v-tab-item>

      <v-tab-item>
        <v-progress-linear
          indeterminate
          color="primary"
          v-if="loading"
        ></v-progress-linear>

        <div v-if="logs.length > 0 && !loading">
          <v-timeline
            align-top
            dense
          >
            <div
              v-for="log in logs"
              :key="log.id"
            >
              <v-timeline-item
                class="align-top mb-2"
                v-if="log.status_desc === 'Campaign is in draft stage.'"
                color="warning"
                fill-dot
                icon="mdi-alert-circle-outline"
              >
                <v-card
                  color="warning"
                  dark
                  width="50%"
                >
                  <small>{{ log.add_time | dateFormat }}</small> <br />
                  <p><strong>{{log.status_desc}}</strong></p>
                </v-card>
              </v-timeline-item>
              <v-timeline-item
                class="align-top mb-2"
                v-if="log.status_desc === 'Campaign is in progress stage.'"
                color="success"
                fill-dot
                icon="mdi-progress-clock"
              >
                <v-card
                  color="success"
                  dark
                  width="50%"
                >
                  <small>{{ log.add_time | dateFormat }}</small> <br />
                  <p><strong>{{log.status_desc}}</strong></p>
                </v-card></v-timeline-item>
              <v-timeline-item
                class="align-top mb-2"
                v-if="log.status_desc === 'Campaign cancelled.'"
                icon="mdi-close-circle-outline"
                fill-dot
                color="error"
              >
                <v-card
                  color="error"
                  dark
                  width="50%"
                >
                  <small>{{ log.add_time | dateFormat }}</small> <br />
                  <p><strong>{{log.status_desc}}</strong></p>
                  <v-card-text class="text--primary">
                    <p> {{log.remark}}</p>
                  </v-card-text>
                </v-card></v-timeline-item>
              <v-timeline-item
                class="align-top mb-2"
                color="primary"
                fill-dot
                v-if="log.status_desc === 'Campaign completed.'"
                icon="mdi-check-circle-outline"
              >
                <v-card
                  color="primary"
                  dark
                  width="50%"
                >
                  <small>{{ log.add_time | dateFormat }}</small> <br />
                  <p><strong>{{log.status_desc}}</strong></p>
                </v-card></v-timeline-item>
            </div>
          </v-timeline>
        </div>
        <div
          class="text-center ma-8"
          v-if="!loading && logs.length <= 0"
        >
          <img
            src="@/assets/images/no-campaign.png"
            width="150"
          />
          <h3>No Logs Found</h3>
        </div>
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>

<script>
import { getInfo, getLog } from '@/api/campaign'
import constantData from '@/utils/constant'
import Influencer from './influencer.vue'
import Stats from './Stats.vue'
export default {
  components: { Influencer, Stats },
  data () {
    return {
      loading: false,
      loading2: false,
      logs: [],
      tab: null,
      totalPages: 0,
      params: {
        page: 1,
        size: 10
      },
      // step: null,
      campaign: {},
      selectedInfluencers: [],
      ytCategory: constantData.ytCategory,
      headersinsta: [
        { text: 'Sr.', value: 'sr', align: 'center' },
        { text: 'Channel', value: 'avatar', align: 'center', width: 60 },
        { text: 'Language', value: 'language', align: 'center' },
        { text: 'Gender', value: 'gender', align: 'center' },
        { text: 'Followers', value: 'followers', align: 'center' },
        { text: 'Total Media', value: 'total_media', align: 'center' },
        { text: 'Add Time', value: 'add_time', align: 'center' }
        // { text: 'Approval', value: 'status', align: 'center' },
        // { text: '', value: 'id', align: 'center' }
      ],
      headersyoutube: [
        { text: 'Sr.', value: 'sr', align: 'center' },
        { text: 'Channel', value: 'avatar', align: 'center', width: 60 },
        { text: 'Language', value: 'language', align: 'center' },
        { text: 'Gender', value: 'gender', align: 'center' },
        { text: 'Subscribers', value: 'subscribers', align: 'center' },
        { text: 'Total View', value: 'total_view', align: 'center' },
        { text: 'Total Post', value: 'total_video', align: 'center' },
        { text: 'Avg View', value: 'latest_avg_view', align: 'center' },
        { text: 'Engagement Rate', value: 'eng_rate', align: 'center', width: 60 },
        { text: 'Last Video Publish Date', value: 'last_video_date', align: 'center', width: 100 },
        { text: 'Add Time', value: 'add_time', align: 'center' }
        // { text: 'Approval', value: 'status', align: 'center' },
        // { text: '', value: 'id', align: 'center' }
      ]
    }
  },
  computed: {
    indexedItems () {
      return this.selectedInfluencers.map((item, index) => ({
        sr: index + 1,
        ...item
      }))
    }
  },
  mounted () {
    if (this.$route.params.campaignId) {
      if (this.$route.params.tab === '3') {
        this.tab = 3
        this.getLogs()
      } else {
        this.tab = 0
        this.getCampaignDetails()
      }
    }
  },
  methods: {
    async getInfluencerData () {
      this.loading2 = true
      this.params.campaignId = this.$route.params.campaignId
      this.params.platform = this.$route.params.platform
      const data = await getInfo(this.params)
      this.selectedInfluencers = data.selectedInfluencers
      this.totalPages = Math.ceil(data.resultCount / 10)
      this.loading2 = false
    },
    // async assignedDelete (item) {
    //   this.loading = true
    //   const data = await assignDelete({ id: item.id })
    //   this.loading = false
    //   if (data.success) {
    //     this.$snackbar({
    //       message: data.message
    //     })
    //     this.getCampaignDetails()
    //   } else {
    //     this.$snackbar({
    //       message: data.message,
    //       color: 'error',
    //       timeout: 5000
    //     })
    //   }
    // },
    async getCampaignDetails () {
      this.loading = true
      this.loading2 = true
      this.params.page = 1
      this.params.size = 10
      this.params.campaignId = this.$route.params.campaignId
      this.params.platform = this.$route.params.platform
      const data = await getInfo(this.params)
      this.campaign = data.campaign
      this.selectedInfluencers = data.selectedInfluencers
      this.campaign.category = this.campaign.category.split(',') || []
      this.campaign.language = this.campaign.language.split(',') || []
      this.campaign.gender = this.campaign.gender.split(',') || []
      this.totalPages = Math.ceil(data.resultCount / 10)
      this.loading = false
      this.loading2 = false
    },
    async getLogs () {
      this.loading = true
      const data = await getLog(this.$route.params.campaignId, this.$route.params.platform)
      this.logs = data.logs
      // this.step = this.logs.length
      this.loading = false
    },
    catName (dataValue) {
      const newvalue = dataValue.split(',')
      var newcat = []
      for (let i = 0; i < newvalue.length; i++) {
        var cat = this.ytCategory.filter(function (obj) {
          return obj.value === newvalue[i]
        }).map(function (obj) {
          return obj.label
        })
        newcat = newcat.concat(cat)
      }
      if (!newcat.length) {
        return '-'
      } else {
        return newcat.toString()
      }
    }
  }
}
</script>

<style scoped>
  h5 {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    text-transform: capitalize;
    color: #6b6b6b;
  }
  p {
    font-weight: 500;
    font-size: 14px;
    color: #333333;
  }
  strong {
    font-weight: 600;
  }
  .grey-bg {
    background: rgba(217, 217, 217, 0.17);
  }
  .v-data-table >>> .v-data-table__wrapper > table {
    border-spacing: 0 12px !important;
  }
  .theme--light.v-data-table >>> .v-data-table__wrapper table th {
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    color: #333333;
    border: none !important;
    padding: 5px 6px;
  }
  .v-data-table >>> .v-data-table__wrapper > table > tbody > tr > td {
    font-weight: normal;
    font-size: 14px;
    line-height: normal;
    color: #333333;
    border: none !important;
    padding: 5px 6px;
  }
  .v-data-table >>> .v-data-table__wrapper > table > tbody > tr {
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.09);
    border-radius: 13px;
  }
  .v-data-table >>> tbody tr.v-data-table__selected {
    background: #e9ecfa;
  }
  .v-image {
    border-radius: 11px;
    box-shadow: -1px 2px 7px rgba(0, 0, 0, 0.11);
    margin: 0 auto;
  }
  .v-image >>> .v-image__image--preload {
    filter: none;
  }
  .img {
    width: 45px;
    height: 45px;
    border-radius: 50%;
  }
  .category {
    display: inline-block;
    text-align: start;
    margin-left: 10px;
  }
  .v-card__title {
    padding-top: 3px;
    padding-bottom: 3px;
    font-size: 15px;
  }
  .v-card >>> .text--primary {
    padding-top: 10px;
  }
  .v-alert small {
    border-radius: 6px;
    border: 1px solid #ccc;
    padding: 6px;
    margin: 5px 0 0;
    display: inline-block;
  }
  .v-timeline-item >>> .v-card {
    line-height: normal;
    padding: 10px 15px;
    color: #fff;
  }
  .v-timeline-item >>> .v-card p {
    color: #fff;
  }
  .v-timeline-item >>> .v-card .v-card__text {
    border: 1px solid #fff;
    border-radius: 8px !important;
    line-height: normal;
    padding: 5px 8px;
    margin: 5px 0 5px;
  }
</style>
