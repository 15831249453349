<template>
  <div class="profile">

    <v-img
      src="@/assets/images/profile-cover.png"
      :aspect-ratio="16/4"
      class="page-cover"
    />
    <v-progress-linear
      class="my-4"
      indeterminate
      color="primary"
      v-if="loading"
    ></v-progress-linear>
    <v-form
      v-else
      v-model="valid"
      v-on:submit.prevent="onSubmit"
      lazy-validation
      ref="clientinfo"
    >
      <v-row
        class="profile-form"
        no-gutters
      >
        <v-col
          cols="3"
          class="profile-cover d-flex justify-center align-center"
        >

          <v-form
            class="picture d-flex align-center justify-center"
            ref="form"
            enctype="multipart/form-data"
          >
            <input
              type="file"
              :name="uploadFieldName"
              :disabled="isSaving"
              @change="filesChange($event.target.name, $event.target.files);
              fileCount = $event.target.files.length"
              accept="image/*"
              class="input-file"
            />
            <v-img
              v-if="(user.avatar && user.avatar !== '') && !isSaving"
              :src="user.avatar"
              :aspect-ratio="16/16"
              width="203"
            />
            <v-icon
              v-else-if="!isSaving"
              large
              color="primary"
            >mdi-image-plus-outline</v-icon>
            <v-progress-circular
              indeterminate
              color="primary"
              v-if="isSaving"
            ></v-progress-circular>
            <v-icon
              v-if="!isSaving && user.avatar"
              color="primary"
              class="img-edit-icon"
            >mdi-image-edit-outline</v-icon>
          </v-form>

        </v-col>
        <v-col cols="9 pt-3">
          <label class="label">Company Name</label>
          <v-text-field
            :rules="clientinfoRules.companyName"
            v-model="user.companyName"
            placeholder="Company Name"
            solo
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="6">
          <label class="label">Contact Person Name</label>
          <v-text-field
            :rules="clientinfoRules.name"
            v-model="user.name"
            placeholder="Contact Name"
            solo
          ></v-text-field>
          <label class="label">Password</label>
          <v-text-field
            v-model="user.password"
            placeholder="Enter new password"
            type="password"
            solo
          ></v-text-field>
        </v-col>
        <v-col cols="6">
          <label class="label">Contact Person Mobile</label>
          <v-text-field
            v-model="user.mobile"
            placeholder="Mobile Number"
            solo
            disabled
          ></v-text-field>
          <label class="label">Contact Person Email</label>
          <v-text-field
            v-model="user.email"
            placeholder="Contact person email"
            solo
          ></v-text-field>
        </v-col>

        <v-col cols="12">
          <h3>Financial Information</h3>
        </v-col>
        <v-col cols="6">
          <label class="label">Company Legal Name</label>
          <v-text-field
            :rules="clientinfoRules.legalName"
            v-model="user.legalName"
            placeholder="Company Legal Name"
            solo
          ></v-text-field>
          <label class="label">GSTIN</label>
          <v-text-field
            :rules="clientinfoRules.gstin"
            v-model="user.gstin"
            placeholder="GSTIN"
            solo
          ></v-text-field>
        </v-col>
        <v-col cols="6">
          <label class="label">Company Address</label>
          <v-text-field
            :rules="clientinfoRules.address"
            v-model="user.address"
            placeholder="Company Address"
            solo
          ></v-text-field>
          <label class="label">PAN</label>
          <v-text-field
            :rules="clientinfoRules.pan"
            v-model="user.pan"
            placeholder="PAN"
            solo
          ></v-text-field>
        </v-col>
        <v-col
          cols="12"
          class="text-right mb-8"
        >
          <v-btn
            depressed
            dark
            :loading="loadingbtn"
            large
            color="primary"
            style="width: 252px;"
            @click="updateProfile()"
          >Update Profile</v-btn>
        </v-col>
      </v-row>
    </v-form>
  </div>
</template>

<script>
import axios from 'axios'
import { getUserInfo, updateProfile } from '@/api/user'

export default {
  data () {
    return {
      valid: true,
      loading: false,
      loadingbtn: false,
      isSaving: false,
      uploadFieldName: 'avatar',
      user: {
        avatar: null
      },
      clientinfoRules: {
        email: [
          v => !!v || 'E-mail is required',
          v => /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) || 'Invalid e-mail'
        ],
        mobile: [
          v => !!v || 'Phone number is required',
          v => /^[+|\d+][0-9]{9}$/.test(v) || 'Invalid Phone number'
        ],
        gstin: [
          v => !!v || 'Gstin number is required',
          v => /^[a-zA-Z0-9\s]{15}$/.test(v) || 'Gstin number should be 15 digit'
        ],
        pan: [
          v => !!v || 'Pan number is required',
          v => /^[a-zA-Z0-9\s]{10}$/.test(v) || 'Pan number should be 10 digit'
        ],
        name: [
          v => !!v || 'Name is required',
          v => /^[a-zA-Z\s]+$/.test(v) || 'Invalid name'
        ],
        legalName: [
          v => !!v || 'Name is required',
          v => /^[a-zA-Z\s]+$/.test(v) || 'Invalid legal name'
        ],
        address: [
          v => !!v || 'Address is required'
        ],
        companyName: [
          v => !!v || 'Company name is required',
          v => /^[a-zA-Z\s]+$/.test(v) || 'Invalid company name'
        ]
      }
    }
  },
  mounted () {
    this.getUserData()
  },
  methods: {
    async getUserData () {
      this.loading = true
      const data = await getUserInfo()
      this.loading = false
      this.user = {
        email: data.data.email,
        mobile: data.data.mobile,
        gstin: data.data.gstin ? data.data.gstin.toUpperCase() : null,
        pan: data.data.pan ? data.data.pan.toUpperCase() : null,
        name: data.data.name,
        legalName: data.data.legal_name,
        address: data.data.address,
        companyName: data.data.company_name,
        avatar: data.data.avatar
      }
    },
    async updateProfile () {
      if (this.$refs.clientinfo.validate()) {
        this.loadingbtn = true
        const submitData = {
          companyName: this.user.companyName,
          name: this.user.name,
          password: this.user.password,
          legalName: this.user.legalName,
          gstin: this.user.gstin ? this.user.gstin.toUpperCase() : null,
          pan: this.user.pan ? this.user.pan.toUpperCase() : null,
          address: this.user.address,
          avatar: this.user.avatar
        }
        const data = await updateProfile(submitData)
        this.loadingbtn = false
        if (data.success) {
          this.$snackbar({
            message: data.message,
            color: 'success'
          })
        } else {
          this.$snackbar({
            message: data.message,
            color: 'error',
            timeout: 5000
          })
        }
      } else {
        window.scrollTo({ top: 0, behavior: 'smooth' })
        console.log('error submit!!')
        return false
      }
    },

    // image upload
    async save (formData) {
      // upload data to the server
      const config = {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: 'Bearer ' + window.localStorage.getItem('user-token')
        }
      }
      const response = await axios.post('/api/operations/image-upload', formData, config)
      if (response.data.success) {
        this.isSaving = false
        this.user.avatar = `${response.data.url}?v=${new Date().valueOf()}`
        this.updateProfile()
      }
      this.isSaving = false
    },

    filesChange (fieldName, fileList) {
      this.isSaving = true
      // handle file changes
      const formData = new FormData()
      if (!fileList.length) return
      if (fileList[0].size > 1048576) {
        this.isSaving = false
        this.$snackbar({
          message: 'Size is too large, Please upload image less than 1MB!',
          color: 'error',
          timeout: 5000
        })
        return
      }
      // append the files to FormData
      Array
        .from(Array(fileList.length).keys())
        .map(x => {
          formData.append(fieldName, fileList[x], fileList[x].name)
        })
      // save it
      this.save(formData)
    }
  }
}
</script>

<style scoped>
  h4 {
    text-transform: uppercase;
    margin: 0 0 20px;
  }
  h3 {
    font-weight: 600;
    font-size: 20px;
    line-height: 30px;
    color: #6b6b6b;
    background: rgba(217, 217, 217, 0.18);
    border-radius: 11px;
    padding: 15px 32px;
    margin: 0 0 10px;
  }
  .profile-cover {
    margin: -130px auto 0;
  }
  .profile-cover .v-image {
    border-radius: 11px;
    box-shadow: -2px 3px 7px rgba(0, 0, 0, 0.16);
    margin: 0 auto;
  }
  .v-image.page-cover {
    border-radius: 8px;
    /* box-shadow: -2px 3px 7px rgba(0, 0, 0, 0.16); */
    margin: 0 auto;
  }

  /* Image Upload */
  .picture {
    text-align: center;
    /* margin: 30px 0 0; */
    border-radius: 11px;
    position: relative;
    cursor: pointer;
    background: #f5f5f5;
    width: 203px;
    height: 203px;
    display: inline-block;
  }
  .picture .img-edit-icon {
    position: absolute;
    bottom: 10px;
    right: 10px;
  }
  .input-file {
    opacity: 0; /* invisible but it's there! */
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    cursor: pointer;
    z-index: 20;
    border-radius: 11px;
  }
  /* .v-progress-circular {
                  margin-top: 60px;
                } */
</style>
