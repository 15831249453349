<template>
  <div class="login">
    <v-row no-gutters>
      <v-col cols="12" md="7" class="login-bg" v-if="showInfo">

        <div class="loginText">

          <div class="text-center">
            <img src="https://ik.imagekit.io/soocel/wp/soocel-spotlight_XqMAWdz-X.png" />
          </div>

          <h1 class="mt-3">Welcome to Soocel</h1>
          <p>At Soocel, we understand the power of influencer marketing and how it can help businesses reach new audiences and grow their brand. With our help, you can tap into the influence and reach of top social media influencers to drive engagement and boost sales for your business. </p>

          <p class="mt-6 mb-2"><strong>Our USP</strong></p>

          <ul>
            <li>More than 50K Influencers across social media platforms</li>
            <li>Low CPI for campaigns</li>
          </ul>

          <div class="text-center mt-8 desktop-hidden">
            <v-btn depressed block color="white" @click="showInfo = false">Continue</v-btn>
          </div>

        </div>

      </v-col>

      <v-col cols="12" md="5">
        <div class="text-center">
           <a href="https://www.soocel.com" class="d-flex justify-center align-center">
          <img src="@/assets/images/soocel-logo.png" height="45" class="logo" />
           </a>
        </div>

        <h2 class="text-center">Client Login</h2>

        <v-form class="login-form">

          <v-radio-group v-model="loginType" row>
            <v-radio color="primary" label="Login with password" value="password"></v-radio>
            <v-radio color="primary" label="Login with OTP" value="otp"></v-radio>
          </v-radio-group>

          <Password v-if="loginType === 'password'" />
          <Otp v-else />

          <p class="d-flex justify-space-between mt-4">
            <router-link to="/user/forgot-password">Forgot Password</router-link>
            <router-link to="/user/register">Register</router-link>
          </p>

          <p class="text-center mt-8">
            By logging in, you are agreeing to the <br>
            <router-link to="/legal/privacy-policy">Privacy Policy</router-link> and <router-link to="/legal/terms">Terms &amp; Conditions</router-link>.
          </p>
        </v-form>

      </v-col>
    </v-row>
  </div>
</template>

<script>
import Password from './components/password.vue'
import Otp from './components/otp.vue'

export default {
  components: { Otp, Password },
  data () {
    return {
      loginType: 'password',
      showInfo: true
    }
  }
}
</script>

<style scoped>
</style>
