<template>
  <div class="login">
    <v-row no-gutters>
      <v-col cols="12" md="7" class="login-bg mobile-hidden">

        <div class="loginText">

          <div class="text-center">
            <img src="https://ik.imagekit.io/soocel/wp/soocel-spotlight_XqMAWdz-X.png" />
          </div>

          <h1 class="mt-3">Welcome to Soocel</h1>
          <p>At Soocel, we understand the power of influencer marketing and how it can help businesses reach new audiences and grow their brand. With our help, you can tap into the influence and reach of top social media influencers to drive engagement and boost sales for your business. </p>

          <p class="mt-6 mb-2"><strong>Our USP</strong></p>

          <ul>
            <li>More than 50K Influencers across social media platforms</li>
            <li>Low CPI for campaigns</li>
          </ul>

          <div class="text-center mt-8 desktop-hidden">
            <v-btn depressed block color="white" @click="showInfo = false">Continue</v-btn>
          </div>

        </div>

        </v-col>
      <v-col
        cols="12"
        md="5"
      >
        <div class="text-center">
          <a
            href="https://www.soocel.com"
            class="d-flex justify-center align-center"
          >
            <img
              src="@/assets/images/soocel-logo.png"
              height="45"
              class="logo"
            />
          </a>
        </div>

        <h2 class="text-center">Client Registration</h2>

        <v-form
          class="login-form"
          v-model="valid"
          lazy-validation
          ref="signupMobile"
          v-on:submit.prevent="onSubmit"
        >
          <v-alert
            outlined
            :type="type"
            v-if="alert"
          >{{ alertMsg }}</v-alert>
          <label class="label">Mobile Number</label>
          <v-text-field
            v-model="loginData.mobile"
            :rules="signupRules.mobile"
            placeholder="Enter Mobile Number"
            solo
            :disabled="verify"
          ></v-text-field>

          <label
            class="label"
            v-if="verify"
          >OTP</label>
          <v-text-field
            v-model="loginData.otp"
            :rules="signupRules.otp"
            placeholder="Enter 6 digit OTP"
            maxlength="6"
            solo
            v-if="verify"
          ></v-text-field>

          <v-btn
            depressed
            dark
            large
            block
            color="primary"
            style="width: 252px;"
            :disabled="loading"
            :loading="loading"
            type="submit"
            @click="verifyOtp()"
            v-if="verify"
          >Verify OTP</v-btn>
          <v-btn
            depressed
            dark
            large
            block
            color="primary"
            style="width: 252px;"
            :disabled="loading"
            :loading="loading"
            type="submit"
            @click="sendOtp()"
            v-else
          >Send OTP</v-btn>
          <div
            class="d-flex justify-center mt-2"
            v-if="verify"
          >
            <p
              style="font-size:14px;"
              class="mt-1"
            >Didn't you receive the OTP?</p>
            <v-btn
              text
              small
              @click="resendOtp"
              color="error"
            >RESEND OTP</v-btn>
          </div>
          <p class="d-flex justify-end mt-4">
            <router-link to="/user/login">Login</router-link>
          </p>

          <p class="text-center mt-8">
            By registering, you are agreeing to the <br>
            <router-link to="/legal/privacy-policy">Privacy Policy</router-link> and <router-link to="/legal/terms">Terms &amp; Conditions</router-link>.
          </p>
        </v-form>

      </v-col>
    </v-row>
  </div>
</template>

<script>
import { otp, verify, retry } from '@/api/user'

export default {
  data () {
    return {
      showInfo: true,
      valid: true,
      type: 'error',
      loading: false,
      verify: false,
      loginData: {},
      user: {},
      alertMsg: '',
      alert: false,
      signupRules: {
        otp: [
          v => !!v || 'OTP is required',
          v => /^[+|\d+][0-9]{5}$/.test(v) || 'Enter 6 digit OTP'
        ],
        mobile: [
          v => !!v || 'Phone Number is required',
          v => /^[+|\d+][0-9]{9}$/.test(v) || 'Invalid Phone number'
        ]
      }
    }
  },
  methods: {
    async sendOtp () {
      if (this.$refs.signupMobile.validate()) {
        try {
          this.loading = true
          const data = await otp(this.loginData)
          if (data.type === 'success') {
            this.verify = true
            this.loading = false
            this.type = 'success'
            this.alertMsg = 'OTP sent successfully'
          } else {
            this.type = 'error'
            this.alertMsg = data.message
            this.loading = false
          }
          this.alert = true
        } catch (error) {
          this.loading = false
          this.type = 'error'
          this.alertMsg = 'Something went wrong'
          this.alert = true
        }

        this.$gtag.query('event', 'click', {
          event_category: 'client_console',
          event_label: 'send_otp'
        })
      } else {
        console.log('error submit!!')
        return false
      }
    },

    async verifyOtp () {
      if (this.$refs.signupMobile.validate()) {
        try {
          this.loading = true
          const data = await verify(this.loginData)
          if (data.token) {
            document.cookie = `client_token=${data.token}; path=/; secure; domain=.soocel.com`
            window.localStorage.setItem('user-token', data.token)
            window.localStorage.setItem('user', JSON.stringify(data.userInfo))
            if (!data.userInfo.email || data.userInfo.email === '') {
              this.$router.push('/user/add-info')
            } else if (!data.userInfo.name || data.userInfo.name === '') {
              this.$router.push('/user/add-info?step=2')
            } else {
              this.$router.push('/dashboard')
            }
          } else {
            this.type = 'error'
            this.alertMsg = data.message
            this.alert = true
          }
          this.loading = false
        } catch (error) {
          this.loading = false
          this.type = 'error'
          this.alertMsg = 'Something went wrong'
          this.alert = true
        }
      } else {
        return false
      }
    },

    async resendOtp () {
      try {
        this.loading = true
        const data = await retry(this.loginData)
        if (data.type === 'success') {
          this.verify = true
          this.loading = false
          this.type = 'success'
          this.alertMsg = 'OTP sent to your registered mobile number'
        } else {
          this.type = 'error'
          this.alertMsg = data.message
          this.loading = false
        }
        this.alert = true
      } catch (error) {
        this.loading = false
        this.type = 'error'
        this.alertMsg = 'Something went wrong'
        this.alert = true
      }
    }
  }
}
</script>

<style scoped>
</style>
