import request from './index'

export const getList = (data) => {
  return request({
    method: 'GET',
    url: '/ranking',
    params: data
  })
}

export const favAdd = (data) => {
  return request({
    method: 'POST',
    url: '/influencer/favourite/add',
    data: data
  })
}

export const favDelete = (data) => {
  return request({
    method: 'DELETE',
    url: '/influencer/favourite/delete',
    data: data
  })
}

export const getFavouriteList = (data) => {
  return request({
    method: 'GET',
    url: '/influencer/favourite/list',
    params: data
  })
}

export const influAssignCampList = (data) => {
  return request({
    method: 'GET',
    url: '/influencer/favourite/assigncamplist',
    params: data
  })
}

export const submitSelectCampaign = (data) => {
  return request({
    method: 'POST',
    url: '/influencer/favourite/assignCampaign',
    data: data
  })
}
