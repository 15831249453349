<template>
  <div class="dashboard">
    <h4 class="mb-4">Campaign Simulation</h4>

    <v-tabs v-model="tab" class="mb-8" background-color="transparent" color="primary" grow>
      <!-- <v-tab>Instagram</v-tab> -->
      <v-tab>YouTube</v-tab>
    </v-tabs>

    <v-tabs-items v-model="tab">
      <!-- <v-tab-item>
        <Instagram />
      </v-tab-item> -->
      <v-tab-item>
        <YouTube />
      </v-tab-item>
    </v-tabs-items>

  </div>
</template>

<script>

// import Instagram from './components/instagram.vue'
import YouTube from './components/youtube.vue'

export default {
  name: 'Dashboard',
  components: { YouTube },
  data () {
    return {
      tab: null
    }
  },
  mounted () {
    // eslint-disable-next-line no-undef, quote-props
    gtag('event', 'conversion', { 'send_to': 'AW-10785553961/d4oKCNy65IgYEKn8-ZYo' })
  }
}
</script>

<style scoped>
  h4 {
    text-transform: uppercase;
    margin: 0;
  }
  .v-tabs {
    border-top: 1px solid #F1F1F1;
  }
  .v-tabs >>> .v-tabs-bar {
    height: 57px;
  }
  .v-tab {
    font-weight: 600;
    font-size: 18px;
    line-height: 57px;
    text-transform: capitalize;
    letter-spacing: 0;
  }
  .v-tab.v-tab {
    color: #1F2964;
  }
  .v-window__container {
    padding: 0 3px;
  }
</style>
