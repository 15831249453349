<template>
  <v-app>
    <router-view />
  </v-app>
</template>

<script>

export default {
  name: 'App',

  data: () => ({
    //
  })
}
</script>

<style>
  body,
  .v-application {
    font-family: "Poppins", sans-serif !important;
  }
  .v-application a {
    text-decoration: none;
    color: #4c62e3;
  }
  .v-application p {
    margin-bottom: 0px !important;
  }
  @media (min-width: 1500px) {
    .container--fluid {
      max-width: 1500px !important;
    }
  }
  h4 {
    font-weight: 800;
    font-size: 20px;
    line-height: 30px;
  }
  h3 {
    font-weight: 500;
    font-size: 20px;
    /* line-height: 30px; */
    color: #6b6b6b;
    margin: 15px 0;
  }
  .v-sheet.v-card {
    box-shadow: -1px 2px 7px rgba(0, 0, 0, 0.11) !important;
    border-radius: 11px !important;
  }
  .v-btn:not(.v-btn--round).v-size--default,
  .v-btn:not(.v-btn--round).v-size--large {
    border-radius: 11px !important;
    height: 48px !important;
    text-transform: capitalize;
    letter-spacing: 0;
    font-weight: 600;
  }
  .v-btn:not(.v-btn--round).v-size--large {
    font-size: 16px;
  }
  .v-application .theme--dark.v-btn.v-btn--disabled.v-btn--has-bg {
    background-color: #4c62e3 !important;
    opacity: 0.85;
  }
  .v-text-field.v-text-field--enclosed.v-text-field--solo {
    margin-top: 5px;
  }
  .v-text-field.v-text-field--enclosed.v-text-field--solo.v-select {
    margin: 0;
  }
  .v-select .v-input__control .v-input__slot,
  .theme--light.v-text-field--solo > .v-input__control > .v-input__slot {
    box-shadow: -2px 3px 7px rgba(0, 0, 0, 0.09) !important;
    border-radius: 11px;
  }
  .v-application .label {
    font-weight: 500;
    line-height: 27px;
    color: #6b6b6b;
    margin: 0 0 10px;
  }
  .theme--light.v-text-field--solo > .v-input__control > .v-input__slot input,
  .v-application--is-ltr
    .v-textarea.v-text-field--enclosed
    .v-text-field__slot
    textarea,
  .v-select__selection {
    line-height: 24px;
    color: #2f2965;
  }
  .v-data-table > .v-data-table__wrapper {
    padding: 0 3px;
  }
  .team-image {
    width: 45px;
    height: 45px;
    border-radius: 50%;
    font-size: 18px;
    padding: 10px;
    background: #f54153;
    color: white;
    text-align: center;
  }
  .channelMeta p {
    margin: 0 0 0 10px;
  }
  .channelMeta p strong {
    font-weight: 500;
  }
  .login-bg {
    color: #fff;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    background: #4c62e3;
  }
  .loginText {
    padding: 0 50px;
  }
  .loginText img {
    width: 65%;
  }
  .loginText p {
    font-size: 16px;
    line-height: 22px;
  }
  .logo {
    margin: 80px 0 20px 0;
  }
  .login-form {
    margin: 20px 15% 0;
  }
  .desktop-hidden {
    display: none !important;
  }
  @media (max-width: 720px) {
    .mobile-hidden {
      display: none !important;
    }
    .desktop-hidden {
      display: block !important;
    }
    .loginText {
      padding: 24px;
    }
    .loginText img {
      width: 80%;
    }
    .loginText h1 {
      font-size: 24px;
      line-height: 32px;
      margin: 0 0 10px;
    }
    .loginText p,
    .loginText ul li {
      font-size: 14px;
      line-height: 20px;
    }
    .login-bg {
      position: fixed;
      z-index: 100;
      top: 0;
    }
    .login-form {
      margin: 20px 5% 0;
    }
  }
</style>
